/** @jsx jsx */
import { jsx, Flex, Grid, Box, Container } from 'theme-ui';
import { graphql } from 'gatsby';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Hero, Layout } from '../components';
import SmartAppBanner from '../components/SmartAppBanner';
import {
  AllParcelWidgets,
  BlogWidget,
  BookingWidget,
  SendParcelWidget,
  TrackingSearchWidget,
} from '../components/widgets';
import { Copy, LinkList, Highlight, FAQ } from '../components/content-modules';
import { getTranslate } from 'react-localize-redux';
import { TIRESET_PRODUCT_ID } from '../state/catalog';
import Link from '../components/Link';
import Alert from '../components/Alert';
import Carousel from '../components/Carousel';
import * as analytics from '../utils/analytics';
import { checkLanguageVersion } from '../utils/contentful';
import Accordion from '../components/Accordion';
import { filterByChannel } from '../hooks/useBlogPosts';
import ContentfulComponent from '../components/ContentfulComponent';

const IndexPage = ({ data, pageContext }) => {
  analytics.usePageCategory('not_set');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const blogPosts = data.allContentfulBlogPost.edges
    .filter(filterByChannel)
    .filter(b => b?.node?.node_locale.startsWith(locale) && checkLanguageVersion(locale, b?.node?.languages));

  const contentfulModules =
    (data.allContentfulLayout.nodes.find(node => node.node_locale.startsWith(locale)) || {}).contentModules || [];

  //const { backgroundImage, headline } = contentfulModules[0];
  const hero = contentfulModules[0];
  let contentModules = [];
  let reverseHighlight = true;

  contentfulModules.map((mod, i) => {
    switch (mod.__typename) {
      case 'ContentfulLayoutHeroImage':
        if (i !== 0) {
          contentModules.push(
            <Hero
              style={mod.visualStyle}
              bg={mod.backgroundImage}
              title={mod.headline}
              backgroundColor={mod.backgroundColor}
              accentColor={mod.accentColor}
              key={i}
            />
          );
        }

        break;
      case 'ContentfulFrequentlyAskedQuestionCategory':
        const { headline, frequentlyAskedQuestions } = mod;
        let faq = [];
        frequentlyAskedQuestions.forEach(q => {
          const { question, answer, contentful_id } = q;
          faq.push(
            <Accordion variant="faq" title={question} id={contentful_id}>
              <div
                dangerouslySetInnerHTML={{
                  __html: answer.childMarkdownRemark.html,
                }}
              />
            </Accordion>
          );
        });

        contentModules.push(<Box sx={{ mb: 3 }}>{faq}</Box>);
        break;
      case 'ContentfulLayoutHighlight':
        if (contentfulModules[i - 1].__typename === 'ContentfulLayoutHighlight') reverseHighlight = !reverseHighlight;
        contentModules.push(
          <Highlight content={mod} reverse={reverseHighlight} key={i} backgroundColor={mod.backgroundColor} />
        );
        break;
      case 'ContentfulComponent':
        const { template, body } = mod;
        if (template !== 'Notification') break;

        contentModules.push(
          <ContentfulComponent template={template} locale={locale} body={body} sxx={{ mt: i === 0 ? 4 : 0, mb: 0 }} />
        );
        break;
      case 'ContentfulLayoutCopy':
        contentModules.push(<Copy content={mod.copy} key={i} />);
        break;
      case 'ContentfulLayoutLinkList':
        contentModules.push(<LinkList links={mod.links} key={i} />);
        break;
      case 'ContentfulLayoutFaq':
        contentModules.push(<FAQ content={mod} key={i} />);
        break;
      default:
        break;
    }
  });

  const alertBanner =
    data.contentfulBlogPost && checkLanguageVersion(locale, data?.contentfulBlogPost?.languages) ? (
      <Alert
        sx={{
          fontWeight: 'semibold',
          textAlign: 'center',
          bg: 'salmon',
          color: 'white',
          borderRadius: 0,
        }}
      >
        {data.contentfulBlogPost.title.trim() + '.'}
        <Link
          to={`/news/${data.contentfulBlogPost.slug}`}
          sx={{
            ml: 2,
            color: 'white',
            textDecoration: 'underline',
            span: { display: 'inline-block', transition: 'all .2s ease' },
            ':hover': {
              span: {
                transform: 'translateX(2px)',
              },
            },
          }}
        >
          {translate('readMore')} <span>&rarr;</span>
        </Link>
      </Alert>
    ) : locale && blogPosts.length ? (
      <Box sx={{ bg: 'blueLighter', py: 3 }}>
        <Container>
          <Flex
            sx={{
              gridColumn: [null, null, '1 / 4'],
              flexDirection: ['column', 'row'],
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ mr: 2, flexShrink: 0 }}>{blogPosts[0].node.publishTime}</Box>
            <Link to={`/news/${blogPosts[0].node.slug}`} sx={{ fontWeight: 'medium', flex: '1 1 auto' }}>
              {blogPosts[0].node.title}
            </Link>
            <Link
              to="/news"
              sx={{ fontWeight: 'medium', mt: [2, 0], textDecoration: 'underline', whiteSpace: [null, null, 'nowrap'] }}
            >
              {translate('allNews')} &nbsp;&rarr;
            </Link>
          </Flex>
        </Container>
      </Box>
    ) : null;

  const [appBanner] = useState(Math.random() < 0.5 ? 'paketit' : 'reitit-ja-liput');

  return (
    <Layout
      title={translate('frontPage')}
      showAppBanner={appBanner}
      path={pageContext.paht}
      paths={pageContext.paths}
      locale={pageContext.locale || 'fi'}
    >
      <Helmet>
        <meta name="facebook-domain-verification" content="x6pnf8bpcghdiyhvz1k1cys7hfr98k" />
      </Helmet>
      {/**
      <Container variant="wide">
        <Grid
          sx={{
            gridTemplateColumns: [
              null,
              null,
              '1fr 1fr',
              '2fr 1fr 1fr',
              'auto minmax(240px, 320px) minmax(240px, 320px)',
            ],
            gridTemplateRows: [
              null,
              'auto auto auto',
              null,
              'auto auto',
              '1fr 1fr',
            ],
            gridGap: [1, null, 2, null, 3],
            '> :nth-child(2)': {
              gridColumn: [null, null, '1 / span 3', 1, '2 / span 2'],
            },
            mx: [-3, 0],
          }}
        >
          {hero.__typename === 'ContentfulLayoutHeroImage' && (
            <Hero
              bg={hero.backgroundImage}
              title={hero.headline}
              sx={{
                gridColumn: [1, null, '1 / 3', null, 1],
                gridRow: [null, null, null, null, '1 / 3'],
              }}
            />
          )}
          {hero.__typename === 'ContentfulCarousel' && (
            <Carousel
              slides={hero.slides}
              sx={{
                gridColumn: [1, null, '1 / span 3', null, 1],
                gridRow: [null, null, null, null, '1 / 3'],
              }}
            />
          )}
          <BookingWidget />
          <SendParcelWidget />
          <TrackingSearchWidget />
          {alertBanner}
        </Grid>
      </Container>
 */}
      {hero.__typename === 'ContentfulCarousel' && <Carousel slides={hero.slides} />}
      {hero.__typename === 'ContentfulLayoutHeroImage' && (
        <Box
          sx={{
            mx: [null, null, null, null, null, 0],
            mb: [1, 3, 4],
            borderRadius: [0, 0, 0, 0, 0, 0],
            overflow: 'hidden',
          }}
        >
          <Hero
            style={hero.visualStyle}
            backgroundImage={hero.backgroundImage}
            backgroundColor={hero.backgroundColor}
            accentColor={hero.accentColor}
            title={hero.headline}
          />
        </Box>
      )}
      <Box>{alertBanner}</Box>
      <Container sx={{ mt: [1, 2, 3, 4] }}>
        <Grid
          sx={{
            mx: [-3, 0],
            gridGap: [1, 3],
            gridTemplateColumns: ['1fr', null, null, '2fr 1.5fr 1.5fr'],
          }}
        >
          <BookingWidget frontPage={true} />
          <SendParcelWidget frontPage={true} />
          <TrackingSearchWidget frontPage={true} />
        </Grid>
        <Grid gap={[4, 5]} my={[4, 5]}>
          <AllParcelWidgets locale={pageContext.locale || 'fi-FI'} center={true} />
          {contentModules && contentModules}
          <BlogWidget posts={blogPosts} />
        </Grid>
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query($node_locale: String! = "fi-FI") {
    allContentfulLayout(filter: { slug: { eq: "home" } }) {
      nodes {
        node_locale
        contentModules {
          ... on ContentfulCarousel {
            ...carouselFields
          }
          ... on ContentfulLayoutHeroImage {
            headline
            visualStyle
            backgroundImage {
              fluid(maxWidth: 1280, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            backgroundColor
            accentColor
            languages
          }
          ... on ContentfulLayoutCopy {
            copy {
              childMarkdownRemark {
                html
              }
            }
          }

          ... on ContentfulFrequentlyAskedQuestion {
            ...faqFields
          }
          ... on ContentfulFrequentlyAskedQuestionCategory {
            ...faqCategoryFields
          }

          ... on ContentfulComponent {
            ...componentFields
          }

          ... on ContentfulLayoutLinkList {
            title
            links {
              title
              icon {
                file {
                  url
                  fileName
                  contentType
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
              page {
                ...pageLinkFields
              }
            }
          }
          ... on ContentfulLayoutHighlight {
            headline
            copy {
              childMarkdownRemark {
                html
              }
            }
            ctaLink
            ctaTitle
            backgroundColor
            visualStyle
            image {
              description
              fluid(maxWidth: 740, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            image2 {
              description
              fluid(maxWidth: 740, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulLayoutFaq {
            headline
            frequentlyAskedQuestions {
              question
              answer {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
    allContentfulBlogPost(filter: { postType: { ne: "Blog" } }, sort: { fields: publishTime, order: DESC }) {
      edges {
        node {
          node_locale
          title
          slug
          publishTime(formatString: "D.M.Y")
          showAlert
          languages
          channel
        }
      }
    }
    contentfulBlogPost(showAlert: { eq: true }, node_locale: { eq: $node_locale }) {
      slug
      title
      languages
    }
  }
`;
